@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;450;500;550&display=swap");
.App {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    height: 100%;
}

* {
    font-family: "Red Hat Display", Ariel, -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif !important;
    font-weight: 500;
    font-feature-settings: normal !important;
    font-variant-numeric: normal !important;
}

#draggable-row tr.drop-over-downward td {
    border-bottom: 2px dashed hsl(209, 100%, 55%);
}

#draggable-row tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
}

@primary-color: #286BB5;