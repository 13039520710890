.tabulated-tables {
    .ant-table-thead {
        font-size: 11px !important;
    }
    .ant-table-row {
        font-size: 11px !important;
    }

    .ant-pagination {
        margin-bottom: 0 !important;
    }
    .ant-collapse-header {
        color: #1f497d !important;
        font-weight: bold;
        font-size: 15px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 !important;
    }
}
