.segment-table-column {
  padding: 8px !important;
  border-left: none !important;
  border-right: none !important;

  .ant-table-column-sorters {
    padding: 8px !important;
  }
}

.selected-row {
  background: #e6f7ff !important;

  & > .ant-table-column-sort {
    background: #e6f7ff !important;
  }

  &:hover > .segment-table-column {
    background: #e6f1ff !important;
  }
}

.segment-table {
  .ant-pagination-item-active {
    border: none !important;
  }

  .ant-table-tbody {
    font-size: 13px;
  }

  @media only screen and (min-width: 500px) {
    .ant-table-container {
      min-height: 260px;
    }
  }
}
