.custom-button,
.custom-button:hover,
.custom-button:focus {
  color: black;
  border-color: white;
}

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus {
  background-color: white;
  border-color: white;
}

.custom-button.disabled .anticon {
  color: rgba(128, 128, 128, 0.6); /* Change the color as desired */
}

.ant-btn:disabled {
  cursor: not-allowed; /* Change the cursor style as desired */
}
